.heading{
    background: -webkit-linear-gradient(to right, #2ebf91, #8360c3);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fcfcfc, #e58469);
}

@media only screen and (max-width: 600px) {
  .selector {
    left: 0% !important;
    margin-left: 1% !important;
  }
  .prev-button{
    margin-left: 1% !important;
  }
  table {
    margin-left: 1% !important;
  }
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select Bill image or pdf';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #fd0808) !important;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
